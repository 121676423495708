import AxiosApi from "./AxiosApi";

const SURVEY_STATE = "SURVEY_STATE";

export default class SurveyApi {
  constructor() {
    this.api = new AxiosApi();
  }

  async getSurvey(alias) {
    return await this.api.get("/surveys/" + alias);
  }

  async getResults(alias, token) {

    if (!token) {
      const storedSurvey = sessionStorage.getItem(SURVEY_STATE);
      return Promise.resolve(!storedSurvey ? JSON.parse(storedSurvey) : null);
    }

    return await this.api.get("/surveys/" + alias + "/results", {
      params: { token }
    });
  }

  async sendResults(alias, answers, isCompleted, token) {
    const results = { answers };
    if (isCompleted) results.completed = true;
    if (token) results.token = token;

    if (!token && !isCompleted) {
      sessionStorage.setItem(SURVEY_STATE, JSON.stringify(results));
      return Promise.resolve();
    }

    return await this.api.post("/surveys/" + alias + "/results", results);
  }
}
