import SurveyApi from './SurveyApi';
import moment from 'moment'
const statechoices={
  AL	:[	'AL',	 'Alabama'	],
  AK	:[	'AK',	 'Alaska'	],
  AZ	:[	'AZ',	 'Arizona'	],
  AR	:[	'AR',	 'Arkansas'	],
  CA	:[	'CA',	 'California'	],
  CO	:[	'CO',	 'Colorado'	],
  CT	:[	'CT',	 'Connecticut'	],
  DE	:[	'DE',	 'Delaware'	],
  DC	:[	'DC',	 'District of Columbia'],
  FL	:[	'FL',	 'Florida'	],
  GA	:[	'GA',	 'Georgia'	],
  HI	:[	'HI',	 'Hawaii'	],
  ID	:[	'ID',	 'Idaho'	],
  IL	:[	'IL',	 'Illinois'	],
  IN	:[	'IN',	 'Indiana'	],
  IA	:[	'IA',	 'Iowa'	],
  KS	:[	'KS',	 'Kansas'	],
  KY	:[	'KY',	 'Kentucky'	],
  LA	:[	'LA',	 'Louisiana'	],
  ME	:[	'ME',	 'Maine'	],
  MD	:[	'MD',	 'Maryland'	],
  MA	:[	'MA',	 'Massachusetts'	],
  MI	:[	'MI',	 'Michigan'	],
  MN	:[	'MN',	 'Minnesota'	],
  MS	:[	'MS',	 'Mississippi'	],
  MO	:[	'MO',	 'Missouri'	],
  MT	:[	'MT',	 'Montana'	],
  NE	:[	'NE',	 'Nebraska'	],
  NV	:[	'NV',	 'Nevada'	],
  NH	:[	'NH',	 'New Hampshire'	],
  NJ	:[	'NJ',	 'New Jersey'	],
  NM	:[	'NM',	 'New Mexico'	],
  NY	:[	'NY',	 'New York'	],
  NC	:[	'NC',	 'North Carolina'	],
  ND	:[	'ND',	 'North Dakota'	],
  OH	:[	'OH',	 'Ohio' 	],
  OK	:[	'OK',	 'Oklahoma'	],
  OR	:[	'OR',	 'Oregon'	],
  PA	:[	'PA',	 'Pennsylvania'	],
  RI	:[	'RI',	 'Rhode Island'	],
  SC	:[	'SC',	 'South Carolina'	],
  SD	:[	'SD',	 'South Dakota'	],
  TN	:[	'TN',	 'Tennessee'	],
  TX	:[	'TX',	 'Texas'	],
  UT	:[	'UT',	 'Utah'	],
  VT	:[	'VT',	 'Vermont'	],
  VA	:[	'VA',	 'Virginia'	],
  WA	:[	'WA',	 'Washington'	],
  WV	:[	'WV',	 'West Virginia'	],
  WI	:[	'WI',	 'Wisconsin'	],
  WY	:[	'WY',	 'Wyoming'	],
  AA  :[  'AA',   'Armed Forces Americas' ],
  AE  :[  'AE',   'Armed Forces Europe'  ],
  AP  :[  'AP',   'Armed Forces Pacific'  ],
  AS  :[  'AS',   'American Samoa'  ],
  GU  :[  'GU',   'Guam'  ],
  MP  :[  'MP',   'Northern Mariana Islands'],  
  PR  :[  'PR',   'Puerto Rico'  ],
  VI  :[  'VI',   'Virgin Islands' ] 
}

const staticValue = {
  first_name: {
    name: ['first_name', 'f_name']
  },
  last_name: {
    name: ['last_name', 'l_name']
  },
  sex: {
    name: ['sex'],
    choices: {
      male: ['male'],
      female: ['female']
    }
  },
  dob: {
    name: ['dob'],
    type: 'date',
    srcformat: 'YYYY-MM-DD',
    destformat: 'MM/DD/YYYY'
  },
  pcp:{
    name:['pc_provider_information'],
    child_name:'pc_provider_name',

  },
  affiliation:{
    name:['affiliation']
  },
  date_of_visit:{
    name: ['date_of_visit'],
    type: 'date',
    srcformat: 'YYYY-MM-DD',
    destformat: 'MM/DD/YYYY'
  },
  gc:{
    name:['gc'],
  },
  state:{
    name:['state1'],
    choices:statechoices
  },
  modality:{
    name: ['modality'],
    choices: {
      phone: ['Phone','phone'],
      video: ['Video','video']
    }
  }
}

function getInitialData() {
  return {
    noSubmissionAfter: null,
    surveyJson: null,
    surveyJsonLoading: false,
    globalError: null,
    answers: null
  };
}
/**
 * 
 * @param {Objectt} answer   result/answers
 * @param {Array} surveydata survay pages element array
 * @param {Object} patientPayload Patient payload
 */
function mapdata(answer, surveydata, patientPayload) {
  // for production kaiser_NW
  if(patientPayload.affiliation=='Kaiser_NW')
  {
  for (var key in staticValue) {
    let staticNamesArr = staticValue[key].name
    let staticchoicesobj = staticValue[key].choices
    let valueExists = surveydata.find(a => a.elements.find(b => staticNamesArr.find(c => c == b.name)));
    if (valueExists) {
      let elementValue = valueExists.elements.find(a => staticNamesArr.find(b => b == a.name));
      if (!(answer && answer[elementValue.name])) {
        let choices = elementValue.choices;
        let type = elementValue.type
        if(type== 'dropdown' && key == 'gc')
        {
          answer[elementValue.name] = patientPayload[key];
        }
        else if(key=='pcp' && patientPayload[key])
        {
            var itemobj={'pc_provider_name':patientPayload[key]}
            answer[staticValue[key].name]=itemobj;
        }
        else if (type == 'radiogroup' || type == 'dropdown') {
          let findstaticchoicearr = staticchoicesobj[patientPayload[key]];
          if (findstaticchoicearr) {
            let finalchoice = choices.find(a => findstaticchoicearr.find(b => b == a.value));
            if(finalchoice && finalchoice.value)
                answer[elementValue.name] = finalchoice.value 
          }
        }
         else if (type == 'checkbox') {
          let finalchoice = [];
          patientPayload[key].forEach(element => {
            let findstaticchoicearr = staticchoicesobj[element];
            let singlechoicevalue = choices.find(a => findstaticchoicearr.find(b => b == a.value));
            if (singlechoicevalue && singlechoicevalue.value)
                finalchoice.push(singlechoicevalue.value);
          });
          answer[elementValue.name] = finalchoice;
        }
        else if (staticValue[key].type && staticValue[key].type == 'date' &&elementValue.inputType&& elementValue.inputType=='date' ) {
          const dobmom = moment(patientPayload[key],staticValue[key].srcformat);
          if(dobmom && dobmom.isValid && dobmom.isValid())
           answer[elementValue.name] = dobmom.format('YYYY-MM-DD');
        } else if (staticValue[key].type && staticValue[key].type == 'date') {
          const dobmom = moment(patientPayload[key],staticValue[key].srcformat);
          if(dobmom && dobmom.isValid && dobmom.isValid())
           answer[elementValue.name] = dobmom.format(staticValue[key].destformat);
        } else {
          answer[elementValue.name] = patientPayload[key];
        }
      }
    }
    else{
      answer[key]=patientPayload[key];
    }
  }
}
  return answer;
}

export default class SurveyController {
  constructor(options) {
    this.options = options;
    this.api = new SurveyApi();
    this.data = getInitialData();
    this.onSendAnswers = this.onSendAnswers.bind(this);
  }

  async loadSurvey() {
    const alias = this.options.alias;
    if (!alias) {
      this.data.globalError = 'No survey alias is provided.';
      return;
    }
    this.data.surveyJsonLoading = true;
    let resultsPromise = null;
    // get JSON and maybe answers
    if (true) resultsPromise = this.api.getResults(alias, this.options.token);
    const responsePromise = await this.api.getSurvey(alias);


    // process survey JSON
    const response = responsePromise;
    if (response.isError) {
      this.data.globalError = response.message;
      return;
    }

    // process survey answers if any
    if (resultsPromise) {
      try {
        const resultsResponse = await resultsPromise;
        let answers = resultsResponse && resultsResponse.data ? resultsResponse.data.payload : null;
        if (answers && this.options.appointmentId && this.options.appointmentId === answers.__appointmentId) {
          // use previous answers only if appointment exists and it's equal to answers appointment
          let pages = responsePromise.data.payload.survey_data.pages;
          answers = mapdata(answers, pages, this.options.patientPayload);
        }
        else {
          let pages = responsePromise.data.payload.survey_data.pages;
          answers = mapdata({}, pages, this.options.patientPayload);
        }
        this.data.answers = answers;
      } catch (error) {
        console.log(error);
      }
    }

   

    const payload = response.data.payload;

    if (payload) {
      this.data.noSubmissionAfter = payload.no_submissions_after;
      this.data.surveyJson = payload.survey_data;
    }

    this.data.surveyJsonLoading = false;
  }

  async onSendAnswers(model, isComplete) {
    const isCompletedAtLeastOnce = /*model.data.__completed ||*/ isComplete;
    const answers = Object.assign({},model.data);
    answers.__metadata = this.options.userInfo;
    answers.__completed = isCompletedAtLeastOnce;
    answers.__currentPageNo = isComplete ? 0 : model.currentPageNo + 1;
    if(this.options && this.options.patientPayload &&this.options.patientPayload.affiliation &&this.options.patientPayload.affiliation=="Kaiser_NW") {
      var patient_obj=this.options.patientPayload;
      const dobmom = moment(patient_obj.date_of_visit,"YYYY-MM-DD");
      let dateofvisit= "";
      if(dobmom && dobmom.isValid && dobmom.isValid())
        {
          dateofvisit=dobmom.format('YYYY-MM-DD');
        }
      answers.patient_obj=
      {
        'Patient Name': patient_obj.first_name+' '+patient_obj.last_name,
        'Date of Birth': patient_obj.dob,
        'PCP': patient_obj.pcp,
        'Referred by':patient_obj.affiliation,
        'Date of Visit':dateofvisit,
        'Genome Medical Provider':patient_obj.gc+', MS, LCGC',
        "Patient's location at time of visit": patient_obj.state,
        'Modality': patient_obj.modality,
        'Sex':patient_obj.sex
      };
    }
    if (this.options.appointmentId) answers.__appointmentId = this.options.appointmentId;
    await this.api.sendResults(this.options.alias, answers, isCompletedAtLeastOnce, this.options.token);
  }
}
