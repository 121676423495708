import 'babel-polyfill';
import Vue from 'vue';
import App from './App.vue';
import SurveyController from './SurveyController';

/**
 *
 * @param
 * {
 *    isIframe: boolean,
 *    elementId: string, // id to mount
 *    alias: string // alias of the survey
 *    token: string
 *    appointmentId: int // id of the current appointment if exists
 *    onComplete: function // function to be called when survey is completed
 * } options
 */
window.GmSetupSurvey = function(options) {
  console.log('survey env', process.env.ENVIRONMENT);

  const controller = new SurveyController(options);
  new Vue({
    el: options.elementId,
    data: controller.data,
    controller: controller,
    render: h => h(App)
  });
  controller.loadSurvey();
};
