import axios from 'axios';

export default class AxiosApi {
  constructor() {
    this.api = axios.create({
      baseURL: this.baseUrl,
      timeout: 35000,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
  }

  get baseUrl() {
    const url = window.location.host;
    if (url.indexOf('dev') > -1) return 'https://surveys.dev.genomemedical.com/api/v1';

    if (url.indexOf('staging') > -1) return 'https://surveys.staging.genomemedical.com/api/v1';

    if (url.indexOf('qa') > -1) return 'https://surveys.qa.genomemedical.com/api/v1';

    if (url === 'portal.genomemedical.com' || url === 'surveys.genomemedical.com')
      return 'https://surveys.genomemedical.com/api/v1';

    if (process.env.ENVIRONMENT === "production")
      return "https://surveys.genomemedical.com/api/v1";
    if (process.env.ENVIRONMENT === "qa")
      return "https://surveys.qa.genomemedical.com/api/v1";
    if (process.env.ENVIRONMENT === "staging")
      return "https://surveys.staging.genomemedical.com/api/v1";

    return 'https://surveys.dev.genomemedical.com/api/v1';
  }

  async get() {
    return await this.process(this.api.get(...arguments));
  }

  async post() {
    return await this.process(this.api.post(...arguments));
  }

  async process(promise) {
    return await new Promise(resolve => promise.then(resolve).catch(error => resolve(this.onError(error))));
  }

  onError(error) {
    error.isError = true;
    error.message = (error.response && error.response.data && error.response.data.message) || error.message;
    return error;
  }
}